import React, {Component} from 'react';
import {RequestsService} from '../service/RequestsService';
import {Panel} from 'primereact/panel';
import {DispensersService} from "../service/DispensersService";
import {DataTable} from 'primereact/datatable';
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import Moment from 'moment';
import {Calendar} from "primereact/calendar";
import {CustomersService} from "../service/CustomersService";
import {Dropdown} from "primereact/dropdown";
import {ProductsService} from "../service/ProductsService";
import {connect} from "react-redux";
import {Chart} from "primereact/chart";
import {Knob} from "primereact/knob";
import {ProgressBar} from "primereact/progressbar";
import {Tag} from "primereact/tag";
import {ssEvents} from "../index";
import {Dialog} from "primereact/dialog";

class Dashboard extends Component {
    convert(rowData) {
        let desc = ""
        if (rowData.output === "BUCKET") desc = "Balde"
        if (rowData.output === "MACHINE") desc = "Máquina"
        if (rowData.output === "DISPENSER") desc = "Pulverizador"
        return (
            <React.Fragment>
                {desc}
                <ProgressBar tooltip="Enter your username" showValue={false}
                             value={rowData.outputDurationMs > 0 ? ((rowData.outputDurationMs / 1000) / rowData.outputConfig) * 100 : 0}/>
                <span className="label-duration"> {(rowData.outputDurationMs / 1000)}s de {rowData.outputConfig}s</span>
            </React.Fragment>
        );
    }

    statusRow(rowData) {
        if (rowData.status === "DONE") {
            if (rowData.outputDurationMs === 0 || rowData.productDurationMs === 0)
                return <Tag className="mr-2" icon="pi pi-check-circle" severity="warning" value="Concluído"/>
            else
                return <Tag className="mr-2" icon="pi pi-check-circle" severity="success" value="Concluído"/>
        }
        if (rowData.status === "CANCELED")
            return <Tag className="mr-2" icon="pi pi-check-circle" severity="danger" value="Cancelado"/>

    }

    responsiveData(rowData) {
        const formatDate = Moment(rowData.date).format('DD-MM-YYYY HH:mm:ss')
        return (
            <React.Fragment>
                {formatDate}
            </React.Fragment>
        );
    }

    responsiveDispenserId(rowData) {
        return (
            <React.Fragment>
                {rowData.dispenserId}
            </React.Fragment>
        );
    }

    responsiveClientName(rowData) {
        return (
            <React.Fragment>
                {rowData.clientName}
            </React.Fragment>
        );
    }

    responsiveCardAlias(rowData) {
        return (
            <React.Fragment>

                {rowData.cardholder}
            </React.Fragment>
        );
    }

    responsiveCardId(rowData) {
        return (
            <React.Fragment>
                {rowData.cardId}
            </React.Fragment>
        );
    }

    responsiveDetergent(rowData) {
        return (
            <React.Fragment>
                {rowData.productName}
                <ProgressBar showValue={false}
                             value={rowData.productDurationMs > 0 ? ((rowData.productDurationMs / 1000) / rowData.productConfig) * 100 : 0}/>
                <span
                    className="label-duration"> {(rowData.productDurationMs / 1000)}s de {rowData.productConfig}s { '- aprox: '+ (rowData.calculatedQuantity ? rowData.calculatedQuantity : '0') +'ml'}</span>
            </React.Fragment>
        );
    }

    constructor() {
        super();
        this.state = {
            registry: {},
            registryDialog: false,
            deleteRegistryDialog: false,
            basicData: null,
            productData: null,
            loading: true,
            requests: [],
            totalDispensers: 1,
            totalOnline: 0,
            machineCount: 0,
            dispenserCount: 0,
            bucketCount: 0,
            machineLast: {},
            dispenserLast: {},
            bucketLast: {},
            totalRecords: 0,
            lazyParams: {
                first: 0,
                rows: 10,
                page: 0,
                sortField: "date",
                sortOrder: -1
            },
            outputs: [{key: "BUCKET", name: "Balde"},
                {key: "MACHINE", name: "Máquina"},
                {key: "DISPENSER", name: "Pulverizador"}]
        }


        this.customersService = new CustomersService();
        this.productsService = new ProductsService();
        this.requestsService = new RequestsService();
        this.dispenserService = new DispensersService();
        this.onPage = this.onPage.bind(this);
        this.onSort = this.onSort.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.deleteRegistry = this.deleteRegistry.bind(this);
        this.hideDeleteRegistryDialog = this.hideDeleteRegistryDialog.bind(this);
        this.confirmDeleteRegistry = this.confirmDeleteRegistry.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.exportCSV = this.exportCSV.bind(this);
        this.refreshRequests = this.refreshRequests.bind(this);
        this.refreshDevices = this.refreshDevices.bind(this);
        this.options = this.getLightTheme();
    }

    deleteRegistry() {
        let requests = this.state.requests.filter(val => val.id !== this.state.registry.id);
        this.requestsService.delete(this.state.registry.id).then(data =>  this.setState({
            requests,
            deleteRegistryDialog: false,
            registry: {}
        }));

    }

    hideDialog() {
        this.setState({
            submitted: false,
            registryDialog: false
        });
    }

    confirmDeleteRegistry(registry) {
        this.setState({
            registry,
            deleteRegistryDialog: true
        });
    }

    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning"
                        onClick={() => this.confirmDeleteRegistry(rowData)}/>
            </React.Fragment>
        );
    }

    exportCSV() {
        this.setState({loading: true});
        let query = this.prepareQuery();
        this.requestsService.export(query).then(value => this.setState({loading: false}));
    }

    prepareQuery() {
        let query = null;
        if (this.state.dates) {
            let dates = this.state.dates;
            if (dates[0] && dates[1]) {
                const start = Moment(dates[0]).format("YYYY-MM-DD 00:00:00");
                const end = Moment(dates[1]).format("YYYY-MM-DD 00:00:00");
                query = "DATE(date) between '" + start + "' and '" + end + "'";
            } else if (dates[0]) {
                const start = Moment(dates[0]).format("YYYY-MM-DD 00:00:00")
                const end = Moment(dates[0]).add(24, 'hours').format("YYYY-MM-DD 00:00:00")
                query = "DATE(date) between '" + start + "' and '" + end + "'";
            }
        }
        if (this.state.customerName && this.state.customerName !== "null") {
            if (query)
                query = query + " and clientNumber=" + this.state.customerName + "";
            else
                query = " clientNumber=" + this.state.customerName + "";

        }
        if (this.state.productName && this.state.productName !== "null") {
            if (query)
                query = query + " and productName='" + this.state.productName + "'";
            else
                query = " productName='" + this.state.productName + "'";

        }
        if (this.state.output && this.state.output !== "null") {
            if (query)
                query = query + " and output='" + this.state.output + "'";
            else
                query = " output='" + this.state.output + "'";

        }
        return query;

    }

    onPage(event) {
        let lazyParams = {...this.state.lazyParams, ...event};
        this.setState({lazyParams}, this.applyFilters);

    }

    onSort(event) {
        let lazyParams = {...this.state.lazyParams, ...event};
        this.setState({lazyParams}, this.applyFilters);
    }

    refreshRequests() {
        this.applyFilters();
        this.requestsService.getCount("MACHINE").then(data => this.setState({machineCount: data}));
        this.requestsService.getCount("DISPENSER").then(data => this.setState({dispenserCount: data}));
        this.requestsService.getCount("BUCKET").then(data => this.setState({bucketCount: data}));
        this.requestsService.getChart().then(data => this.setState({basicData: data}));
        this.requestsService.getChartProducts().then(data => this.setState({productData: data}));
        this.requestsService.getLast("MACHINE").then(data => this.setState({machineLast: data}));
        this.requestsService.getLast("DISPENSER").then(data => this.setState({dispenserLast: data}));
        this.requestsService.getLast("BUCKET").then(data => this.setState({bucketLast: data}));
    }

    refreshDevices() {
        this.dispenserService.getCount().then(data => this.setState({totalDispensers: data}));
        this.dispenserService.getOnline().then(data => this.setState({totalOnline: data}));
    }


    componentWillUnmount() {
        ssEvents.removeEventListener("requests_update", this.refreshRequests);
        ssEvents.removeEventListener("device_health", this.refreshDevices);
    }

    applyFilters = () => {
        this.setState({loading: true});
        let query = this.prepareQuery();
        this.requestsService.getList(query, this.state.lazyParams.first, this.state.lazyParams.rows, this.state.lazyParams.page, this.state.lazyParams.sortField, this.state.lazyParams.sortOrder).then(res => this.setState({requests: res.data}, this.setState({totalRecords: res.headers['x-total-count']}, this.setState({loading: false}))));
    }

    componentDidMount() {
        ssEvents.addEventListener("requests_update", this.refreshRequests);
        ssEvents.addEventListener("device_health", this.refreshDevices);
        this.refreshRequests();
        this.refreshDevices();
        this.customersService.getList().then(data => this.setState({customers: data}));
        this.productsService.getList().then(data => this.setState({products: data}));
    }

    filters = () => {

        return <div className="p-fluid p-grid p-pl-1 p-pt-10">
            <div className="p-field p-col-12 p-md-6 p-lg-2 p-px-1">
                                <span className="p-float-label">
                                     <Calendar id="date-filter" showButtonBar locale="pt"
                                               onClearButtonClick={() => this.setState({dates: null}, this.applyFilters)}
                                               selectionMode="range" value={this.state.dates}
                                               dateFormat={"dd-mm-yy"} onChange={(e) => {
                                         this.setState({dates: e.value}, this.applyFilters)
                                     }}/>
                                    <label
                                        htmlFor="date-filter">{this.state.dates ? 'Data' : 'Escolha o intervalo de datas'}</label>
                                </span>
            </div>
            {this.props.user.platformAdmin &&
                <div className="p-field p-col-12 p-md-6 p-lg-2 p-px-1">
                                <span className="p-float-label">
                                   <Dropdown id="client-filter" optionLabel="name" optionValue={"number"}
                                             value={this.state.customerName}
                                             options={this.state.customers} showClear
                                             onChange={(e) => this.setState({customerName: e.value}, this.applyFilters)}
                                   />
                                    <label
                                        htmlFor="client-filter">{this.state.customerName ? 'Cliente' : 'Escolha o cliente'}</label>
                                </span>
                </div>
            }
            <div className="p-field p-col-12 p-md-6 p-lg-2 p-px-1">
                                <span className="p-float-label">
                                   <Dropdown id="product-filter" optionLabel="name" optionValue={"name"}
                                             value={this.state.productName}
                                             options={this.state.products} showClear
                                             onChange={(e) => this.setState({productName: e.value}, this.applyFilters)}
                                   />
                                    <label
                                        htmlFor="client-filter">{this.state.productName ? 'Detergente' : 'Escolha o detergente'}</label>
                                </span>
            </div>
            <div className="p-field p-col-12 p-md-6 p-lg-2 p-px-1">
                                <span className="p-float-label">
                                   <Dropdown id="product-filter" optionLabel="name" optionValue={"key"}
                                             value={this.state.output}
                                             options={this.state.outputs} showClear
                                             onChange={(e) => this.setState({output: e.value}, this.applyFilters)}
                                   />
                                    <label
                                        htmlFor="client-filter">{this.state.output ? 'Recipente' : 'Escolha o recipente'}</label>
                                </span>
            </div>
            {!this.props.user.platformAdmin &&
                <div className="p-field p-col-12 p-md-6 p-lg-2 p-px-1">
                </div>
            }
            <div className="p-col p-col-4">
                <div style={{textAlign: 'right'}}> <Button  className="p-button-export" type="button" icon="pi pi-external-link"
                                                          label="Exportar" onClick={this.exportCSV}/>
                </div>
            </div>
        </div>


    }

    hideDeleteRegistryDialog() {
        this.setState({deleteRegistryDialog: false});
    }

    getLightTheme() {
        let basicOptions = {
            maintainAspectRatio: false,
            aspectRatio: .6,
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
            }
        };
        return {
            basicOptions
        }
    }

    render() {
        const deleteRegistryDialogFooter = (
            <React.Fragment>
                <Button label="Não" icon="pi pi-times" className="p-button-text"
                        onClick={this.hideDeleteRegistryDialog}/>
                <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={this.deleteRegistry}/>
            </React.Fragment>
        );
        const {basicOptions} = this.options;
        const header = this.filters()
        return <div className="p-grid dashboard">
            <div className="p-col-12">
                <Panel className="circle-panel">
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-3 p-md-6">
                            <div className="status-title" style={{color: '#424242'}}>DISPENSADORES ONLINE</div>
                            <Knob className="status-desc" max={this.state.totalDispensers}
                                  value={this.state.totalOnline} min={0}
                                  valueTemplate={"{value}/" + this.state.totalDispensers} valueColor={"#22C55E"}
                                  readOnly={true}/>
                        </div>

                        <div className="p-col-12 p-lg-3 p-md-6">
                            <div className="status-title">
                                <img src="assets/layout/images/sprayPK.png" alt="apollo-layout" className="output-icon"/>
                            </div>
                            <div className="status-title" style={{color: '#294560'}}>PULVERIZADOR</div>

                            <div className="status-value"
                                 style={{color: '#294560'}}>{this.state.dispenserCount}</div>
                            <div className="status-desc" style={{color: '#424242'}}>ultimo acesso
                                em {Moment(this.state.dispenserLast.date).format('DD-MM-YYYY HH:mm:ss')}</div>
                        </div>
                        <div className="p-col-12 p-lg-3 p-md-6">
                            <div className="status-title">
                            <img src="assets/layout/images/baldePK.png" alt="apollo-layout" className="output-icon"/>
                            </div>
                            <div className="status-title" style={{color: '#294560'}}>BALDE</div>
                            <div className="status-value" style={{color: '#294560'}}>{this.state.bucketCount}</div>
                            <div className="status-desc" style={{color: '#424242'}}>ultimo acesso
                                em {Moment(this.state.bucketLast.date).format('DD-MM-YYYY HH:mm:ss')}</div>

                        </div>
                        <div className="p-col-12 p-lg-3 p-md-6">
                            <div className="status-title">
                                <img src="assets/layout/images/machinePK.png" alt="apollo-layout" className="output-icon"/>
                            </div>
                            <div className="status-title" style={{color: '#294560'}}>MÁQUINA</div>
                            <div className="status-value" style={{color: '#294560'}}>{this.state.machineCount}</div>
                            <div className="status-desc" style={{color: '#424242'}}>ultimo acesso
                                em {Moment(this.state.machineLast.date).format('DD-MM-YYYY HH:mm:ss')}</div>

                        </div>

                    </div>
                </Panel>
            </div>
            <div className="p-col-12">
                <Panel>
                    <Chart type="line" data={this.state.basicData} options={basicOptions}/>
                </Panel>
            </div>
            <div className="p-col-12">
                <Panel>
                    <Chart type="bar" data={this.state.productData} options={basicOptions}/>
                </Panel>
            </div>
            <div className="p-col-12">
                <div className="datatable-responsive">
                    <div className="card">
                        <h1>Registo de Pedidos</h1>
                        <DataTable lazy paginator loading={this.state.loading}
                                   first={this.state.lazyParams.first} rows={this.state.lazyParams.rows}
                                   totalRecords={this.state.totalRecords}
                                   onPage={this.onPage}
                                   onSort={this.onSort} sortField={this.state.lazyParams.sortField}
                                   sortOrder={this.state.lazyParams.sortOrder}
                                   paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                   currentPageReportTemplate="{first} até {last} de {totalRecords}"
                                   rowsPerPageOptions={[10, 20, 50]}
                                   className="p-datatable-responsive" ref={(el) => {
                            this.dt = el;
                        }} header={header} value={this.state.requests}>
                            <Column body={this.responsiveData} field="date" header="Data" sortable></Column>
                            <Column body={this.responsiveDispenserId} field="dispenserId" header="Dispensador"
                                    sortable></Column>
                            {this.props.user.platformAdmin &&
                                <Column body={this.responsiveClientName} field="clientName" header="Cliente"
                                        sortable></Column>
                            }
                            <Column body={this.responsiveCardAlias} field="cardholder" header="Utilizador"
                                    sortable></Column>
                            <Column body={this.responsiveCardId} field="cardId" header="Cartão" sortable></Column>
                            <Column body={this.responsiveDetergent} field="productName" header="Detergente"
                                    sortable></Column>
                            <Column body={this.convert} field="output" header="Recipiente" sortable></Column>
                            <Column className="p-col-1"  body={this.statusRow} field="status" header="Status" sortable></Column>
                            {this.props.user.platformAdmin && <Column className="col-options" body={this.actionBodyTemplate}></Column>}
                        </DataTable>
                    </div>
                </div>
            </div>
            <Dialog visible={this.state.deleteRegistryDialog} style={{width: '450px'}} header="Confirmação" modal
                    footer={deleteRegistryDialogFooter} onHide={this.hideDeleteRegistryDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{fontSize: '2rem'}}/>
                    {this.state.registry && <span>Tem a certeza que deseja apagar o registo efetuado na data<b> {Moment(this.state.registry.date).format('DD-MM-YYYY HH:mm:ss')}</b>  no cliente <b>{this.state.registry.clientName}</b>?</span>}
                </div>
            </Dialog>
        </div>
    }
}

function mapDispatchToProps({auth}) {
    const {user} = auth;
    return {user};
}

export default connect(mapDispatchToProps, {})(Dashboard)