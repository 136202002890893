import axios from 'axios';
import {ENDPOINT} from "../Properties";
import {saveAs} from 'file-saver';

export class RequestsService {

    getCount(type) {
        return axios.get(ENDPOINT + '/requests/count/' + type)
            .then(res => res.data);
    }
    getChart() {
        return axios.get(ENDPOINT + '/requests/chart-current-month')
            .then(res => res.data);
    }
    getChartProducts() {
        return axios.get(ENDPOINT + '/requests/chart-current-month-product')
            .then(res => res.data);
    }

    delete(id) {
        return axios.delete(ENDPOINT + '/requests/'+id)
            .then(res => res.data);
    }
    getList(fq, first, rows, page, sort, order) {
        return axios.get(ENDPOINT + (fq ? '/requests?fq=' + fq : '/requests'), {
            headers: {
                'pageX-first': first,
                'pageX-rows': rows,
                'pageX-page': page,
                'pageX-sort': sort,
                'pageX-order': order,
            }
        })
            .then(res => res);
    }

    getLast(type) {
        return axios.get(ENDPOINT + '/requests/last/' + type)
            .then(res => res.data);
    }

    export(fq) {
        let req = fq ? '/requests/export?fq=' + fq : '/requests/export';
        return axios.get(ENDPOINT + req, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/vnd.ms-excel',
                'Accept': 'application/vnd.ms-excel'

            }
        }).then((response) => saveAs(new Blob([response.data]), response.headers["x-filename"]));
    }

}