import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {connect} from "react-redux";


const PrivateRoute = ({component: Component, ...rest}) => {

 return   <Route {...rest} render={props => {
        if (!rest.isAuthenticated) {
            return <Redirect to={{pathname: '/login', state: {from: props.location}}}/>
        }
        return <Component {...props} />
    }}/>
}

function mapDispatchToProps({auth}) {
    const {isAuthenticated} = auth;
    return  {isAuthenticated};
}

export default connect(mapDispatchToProps, {})(PrivateRoute)