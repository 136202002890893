import React, {Component} from 'react';
import {InputText} from 'primereact/inputtext';
import {Button} from "primereact/button";
import {Toast} from 'primereact/toast';
import {withRouter} from "react-router-dom"
import {connect} from 'react-redux';
import {requestLogin} from "../actions/auth";

class Login extends Component {
	state = {
		username:"",
		password:""
	}

	onSubmit= (e) => {
		e.preventDefault();
		this.props.login({
			username: this.state.username,
			password: this.state.password
		}, this.onSuccess, this.onError);
	}

	onSuccess = ()=> {
		this.props.history.push("/dashboard")
	}

	onError = ()=> {
		let msg = {severity: 'error', summary: 'Autenticação', detail: "Login Inválido"};
		this.growl.show(msg);
	}

	onChange = (e) => {
		const {name, value} = e.target
		this.setState({
			[name]: value
		})
	}
	render() {
		return <div className="login-body">
			<Toast ref={(el) => this.growl = el} style={{marginTop: '75px'}}/>
			<div className="body-container">
				<div className="p-grid p-nogutter">
					<div className="p-col-12 p-lg-6 left-side">
						<img src="assets/layout/images/logotop_800.png" alt="apollo-layout" className="logo"/>
						<h1>Cloud</h1>
						<p>
							Gestão centralizada de dispensadores.
						</p>
					</div>

					<div className="p-col-12 p-lg-6 right-side">
						<div className="login-wrapper">
							<div className="login-container">
								<span className="title">Bem vindo</span>
								<form className="login-form" onSubmit={this.onSubmit}>
								<div className="p-grid p-fluid">
									<div className="p-col-12">
										<InputText value={this.state.username}
												   name="username"  onChange={this.onChange} placeholder="Nome de Utilizador"/>
									</div>
									<div className="p-col-12">
										<InputText type="password" value={this.state.password} feedback={false}
												   name="password" onChange={this.onChange} placeholder="Palavra Passe"/>
									</div>
									<div className="p-col-12">
										<Button label="Entrar" icon="pi pi-check"  />
									</div>
									<div className="p-col-6 password-container">

									</div>
								</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	}
}
function mapDispatchToProps({auth}) {
	return auth;
}

export default withRouter( connect(mapDispatchToProps, {
	login: requestLogin
})(Login))