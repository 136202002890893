import React, {Component} from 'react';
import {Redirect, Route, Switch, withRouter} from 'react-router-dom';
import App from "./App";
import Login from "./pages/Login";
import Error from "./pages/Error";
import NotFound from "./pages/NotFound";
import Access from "./pages/Access";
import PrivateRoute from "./components/PrivateRoute";
import {setupRequestInterceptor, setupResponseInterceptor} from "./service/AxiosInterceptor";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import {configureStore} from "./store";

const {persistor, store} = configureStore();
class AppWrapper extends Component {
	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			window.scrollTo(0, 0)
		}
	}
	componentDidMount() {
		setupRequestInterceptor(store);
		setupResponseInterceptor(store);
	}
	renderContent() {
		return (<Switch>

			<Route exact path="/login" >
				<Login/>
			</Route>
			<Route exact path="/error" component={Error}/>
			<Route exact path="/notfound" component={NotFound}/>
			<Route exact path="/access" component={Access}/>
			<PrivateRoute path="/" component={App}/>
			<Redirect to={"/login"}/>
		</Switch>)
	}
	render() {
		return (<PersistGate persistor={persistor}>
			<Provider store={store}>
				{this.renderContent()}
			</Provider>
		</PersistGate>);
	}
}

export default withRouter(AppWrapper);