import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {requestLogout} from "./actions/auth";
import {connect} from "react-redux";
import {DispensersService} from "./service/DispensersService";

class AppTopbar extends Component {
    handleLogout = () => {
        this.props.logout();
    }

    static defaultProps = {
        onMenuButtonClick: null,
        onTopbarMenuButtonClick: null,
        onTopbarItemClick: null,
        topbarMenuActive: false,
        activeTopbarItem: null,
        darkTheme: null,
        onThemeChange: null
    }

    static propTypes = {
        onMenuButtonClick: PropTypes.func.isRequired,
        onTopbarMenuButtonClick: PropTypes.func.isRequired,
        onTopbarItemClick: PropTypes.func.isRequired,
        topbarMenuActive: PropTypes.bool.isRequired,
        activeTopbarItem: PropTypes.string,
        darkTheme: PropTypes.bool,
        onThemeChange: PropTypes.func
    }

    constructor() {
        super();
        this.state = {};
        this.dispenserService = new DispensersService();
        this.dispenserService.getCount().then(data => this.setState({totalDispensers: data}));
    }

    onTopbarItemClick(event, item) {
        if (this.props.onTopbarItemClick) {
            this.props.onTopbarItemClick({
                originalEvent: event,
                item: item
            });
        }
    }

    render() {
        let topbarItemsClassName = classNames('topbar-menu fadeInDown', {'topbar-menu-visible': this.props.topbarMenuActive});

        return <div className="topbar clearfix">

            <button className="p-link" id="menu-button" onClick={this.props.onMenuButtonClick}>
                <i className="pi pi-bars"></i>
            </button>

            <img className="logo" alt="apollo-layout" src="assets/layout/images/logo_br_100.png"/>

            <button className="p-link profile" onClick={this.props.onTopbarMenuButtonClick}>
                <span className="username">{this.props.user.name}</span>
                <img src="assets/layout/images/avatar/avatar.png" alt="apollo-layout"/>
                <i className="pi pi-angle-down"></i>
            </button>

            <ul className={topbarItemsClassName}>
                <li className={classNames({'menuitem-active': this.props.activeTopbarItem === 'profile'})}
                    onClick={(e) => this.onTopbarItemClick(e, 'profile')}>
                    <button onClick={this.handleLogout} className="p-link">
                        <i className="topbar-icon pi pi-fw pi-power-off"></i>
                        <span className="topbar-item-name">Terminar Sessão</span>
                    </button>
                </li>
            </ul>
        </div>;
    }
}

function mapDispatchToProps({auth}) {
    const {user} = auth;
    return {user};
}

export default connect(mapDispatchToProps, {logout: requestLogout})(AppTopbar)