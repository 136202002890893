import {applyMiddleware, compose, createStore} from 'redux';
import ReduxThunk from 'redux-thunk';
import {persistCombineReducers, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import auth from './reducers/auth';

const isDevVersion = process.env.NODE_ENV === 'development';
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middlewares = [ReduxThunk];

const volatileReducers = [];

const config = {
    key: 'root',
    storage,
    debug: isDevVersion,
    blacklist: volatileReducers,
};

const reducers = persistCombineReducers(config, {
    auth
});

export const configureStore = () => {
    const store = createStore(
        reducers,
        composeEnhancers(applyMiddleware(...middlewares)),
    );

    const persistor = persistStore(store);

    // persistor.purge();
    return {persistor, store};
};
