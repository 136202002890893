import React, {Component} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {CardsService} from "../service/CardsService";
import {InputText} from 'primereact/inputtext';
import {Dropdown} from 'primereact/dropdown';
import {CustomersService} from "../service/CustomersService";
import {ssEvents} from "../index";
import {Tag} from "primereact/tag";
import {connect} from "react-redux";
import {Dialog} from "primereact/dialog";



class Cards extends Component {


    responsiveRowUuid(rowData,) {
        return (

            <Tag className="mr-2" icon="pi pi-credit-card" severity="info" value={rowData.uuid}/>


        );
    }

    responsiveCurrentAlias(rowData,) {
        return (
            <React.Fragment>
                {rowData.cardholder}
            </React.Fragment>
        );
    }

    responsiveClientId(rowData) {
        return (
            <React.Fragment>
                {rowData.clientName}
            </React.Fragment>
        );
    }
    editCard(card) {
        this.setState({
            card,
            cardDialog: true
        });
    }
    constructor() {
        super();
        this.state = {
            editingRows: {},
            cards: [],
            deleteCardDialog:false,
            cardDialog:false,
            customers: [],
            card: {}
        };
        this.cardsService = new CardsService();
        this.customersService = new CustomersService();
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.editCard = this.editCard.bind(this);
        this.saveCard = this.saveCard.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.exportCSV = this.exportCSV.bind(this);
        this.hideDeleteCardDialog = this.hideDeleteCardDialog.bind(this);
        this.deleteCard = this.deleteCard.bind(this);
        this.confirmDeleteCard = this.confirmDeleteCard.bind(this);
        this.refreshSse = this.refreshSse.bind(this);

    }

    refreshSse(e) {
        this.cardsService.getList().then(data => this.setState({cards: data}));
    }

    componentWillUnmount() {
        ssEvents.removeEventListener("card_update", this.refreshSse);
    }

    exportCSV() {
        this.dt.exportCSV();
    }
    confirmDeleteCard(card) {
        this.setState({
            card,
            deleteCardDialog: true
        });
    }
    componentDidMount() {
        this.cardsService.getList().then(data => this.setState({cards: data}));
        this.customersService.getList().then(data => this.setState({customers: data}));
        ssEvents.addEventListener("card_update", this.refreshSse);

    }
    hideDialog() {
        this.setState({
            submitted: false,
            cardDialog: false
        });
    }
    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2"
                        onClick={() => this.editCard(rowData)}/>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning"
                        onClick={() => this.confirmDeleteCard(rowData)}/>
            </React.Fragment>
        );
    }
    saveCard(){
        this.cardsService.update(this.state.card).then(data => this.cardsService.getList().then(data => this.setState({
            cardDialog: false,
            cards: data})));
    }
    hideDeleteCardDialog() {
        this.setState({deleteCardDialog: false});
    }
    deleteCard() {
        let cards = this.state.cards.filter(val => val.id !== this.state.card.id);
        this.cardsService.delete(this.state.card.id).then(data =>  this.setState({
            cards,
            deleteCardDialog: false,
            card: {}
        }));

    }
    render() {
        
        const deleteCardDialogFooter = (
            <React.Fragment>
                <Button label="Não" icon="pi pi-times" className="p-button-text"
                        onClick={this.hideDeleteCardDialog}/>
                <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={this.deleteCard}/>
            </React.Fragment>
        );
        const cardDialogFooter = (
            <React.Fragment>
                <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog}/>
                <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={this.saveCard}/>
            </React.Fragment>
        );
        const header = <div style={{textAlign: 'left'}}><Button type="button" icon="pi pi-external-link"
                                                                label="Exportar" onClick={this.exportCSV}></Button>
        </div>;
        return <div className="p-grid dashboard">
            <div className="p-col-12">
                <div className="datatable-responsive">
                    <div className="card">
                        <DataTable  filterDisplay="row" editMode="row" dataKey="uuid" onRowEditInit={this.onRowEditInit}
                                   onRowEditSave={this.onRowEditSave} onRowEditCancel={this.onRowEditCancel}
                                   className="p-datatable-responsive" ref={(el) => {
                            this.dt = el;
                        }} header={header} value={this.state.cards}>
                            <Column filterMatchMode="contains" filter  className="p-col-1" body={this.responsiveRowUuid} field="uuid" header="Cartão"
                                    sortable></Column>
                            <Column filterMatchMode="contains" filter body={this.responsiveClientId} field="clientName" header="Cliente"
                                    sortable></Column>
                            <Column filterMatchMode="contains" filter body={this.responsiveCurrentAlias} field="cardholder" header="Nome do Utilizador"
                                    sortable></Column>
                            {this.props.user.platformAdmin &&
                                <Column className="col-options" body={this.actionBodyTemplate}></Column>}
                        </DataTable>
                    </div>
                    <Dialog visible={this.state.deleteCardDialog} style={{width: '450px'}} header="Confirmação" modal
                            footer={deleteCardDialogFooter} onHide={this.hideDeleteCardDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{fontSize: '2rem'}}/>
                            {this.state.card && <span>Tem a certeza que deseja apagar o cartão<b> {this.state.card.uuid}</b>?</span>}
                        </div>
                    </Dialog>
                    <Dialog visible={this.state.cardDialog} style={{width: '450px'}} header="Detalhes do Cartao" modal
                            className="p-fluid" footer={cardDialogFooter} onHide={this.hideDialog}>
                        <div className="card-tag">
                        <Tag className="mr-4 card-tag" icon="pi pi-credit-card" severity="info" value={this.state.card.uuid}/>
                        </div>
                        <div className="p-field">
                            <label htmlFor="name">Cliente</label>
                            <Dropdown optionLabel="name" optionValue={"number"} value={this.state.card.clientNumber}
                                      showClear options={this.state.customers} onChange={(e) => this.setState({
                                card: {
                                    ...this.state.card,
                                    clientNumber: e.target.value,
                                    cardholder:  ""
                                }
                            })} placeholder="Escolha o Cliente"/>
                        </div>
                        <div className="p-field">
                            <label htmlFor="name">Nome do Utilizador</label>
                            <InputText disabled={!this.state.card.clientNumber} id="name" value={this.state.card.cardholder}
                                       onChange={(e) => this.setState({
                                           card: {
                                               ...this.state.card,
                                               cardholder: e.target.value
                                           }
                                       })} required/>
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    }
}

function mapDispatchToProps({auth}) {
    const {user} = auth;
    return {user};
}

export default connect(mapDispatchToProps, {})(Cards)