import axios from 'axios';
import {ENDPOINT} from "../Properties";


export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const SESSION_RESET = 'SESSION_RESET';

const requestLoginActionCreator = () => ({
    type: LOGIN_REQUEST,
});

const receiveLoginActionCreator = (auth) => ({
    type: LOGIN_SUCCESS,
    payload: auth
});

const loginErrorActionCreator = () => ({
    type: LOGIN_FAILURE,
});

const requestLogoutActionCreator = () => ({
    type: LOGOUT_REQUEST,
});


export const requestLogin = (formData, onSuccess, onFailure) => {
    return async dispatch => {
        dispatch(requestLoginActionCreator());
        try {
            const {data, status, headers} = await axios.post(ENDPOINT + "/users/authenticate", {
                ...formData,
            });

            if (status === 200) {
                dispatch(receiveLoginActionCreator({user: data, token: headers.authorization}));
                if (onSuccess) onSuccess();
            }
        } catch (error) {
            dispatch(loginErrorActionCreator());
            if (onFailure) onFailure();
        }
    };
};
export  const resetSessionActionCreator = () => ({
    type: SESSION_RESET,
});

export const requestLogout = () => {
    return dispatch => {
        dispatch(requestLogoutActionCreator());
    };
};

