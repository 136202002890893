import React, {Component} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from "primereact/column";
import {Button} from 'primereact/button';
import {classNames} from 'primereact/utils';
import {UsersService} from "../service/UsersService";
import {Toolbar} from 'primereact/toolbar';
import {InputNumber} from 'primereact/inputnumber';
import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';
import {Toast} from 'primereact/toast';
import {Password} from 'primereact/password';

export class Users extends Component {


    responsiveName(rowData) {
        return (
            <React.Fragment>

                {rowData.name}
            </React.Fragment>
        );
    }

    responsiveRole(rowData) {
        let roles = {
            PLATFORM_ADMIN: "ADMINISTRADOR",
            STORE_ADMIN: "CLIENTE",
            STORE_USER: "FUNCIONÁRIO"
        };
        return (
            <React.Fragment>
                {roles[rowData.role]}
            </React.Fragment>
        );
    }

    responsiveEmail(rowData) {
        return (
            <React.Fragment>

                {rowData.email}
            </React.Fragment>
        );
    }

    responsiveActive(rowData) {
        return (
            <React.Fragment>

                {rowData.inactive ?  'NÃO' :  'SIM'}
            </React.Fragment>
        );
    }

    responsiveCustomerId(rowData) {
        return (
            <React.Fragment>

                {rowData.customerId}
            </React.Fragment>
        );
    }
    responsiveLastLogin(rowData) {
        return (
            <React.Fragment>

                {rowData.lastLogin}
            </React.Fragment>
        );
    }

    constructor() {
        super();
        this.state = {
            users: [],
            userDialog: false,
            deleteUserDialog: false,
            user: this.emptyUser,
            submitted: false,
        };

        this.usersService = new UsersService();
        this.exportCSV = this.exportCSV.bind(this);
        this.openNew = this.openNew.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.rightToolbarTemplate = this.rightToolbarTemplate.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.saveUser = this.saveUser.bind(this);
        this.editUser = this.editUser.bind(this);
        this.confirmDeleteUser = this.confirmDeleteUser.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.onInputNumberChange = this.onInputNumberChange.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.hideDeleteUserDialog = this.hideDeleteUserDialog.bind(this);

    }
    onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        let user = {...this.state.user};
        user[`${name}`] = val;

        this.setState({ user });
    }

    exportCSV() {
        this.dt.exportCSV();
    }
    emptyUser = {
        name: '',
        password: '',
        email:'',
        customerId: 0,
        customerName: 0,
    };
    editUser(user) {
        this.setState({
            user: { ...user },
            userDialog: true
        });
    }
    confirmDeleteUser(user) {
        this.setState({
            user,
            deleteUserDialog: true
        });
    }

    deleteUser() {
        let users = this.state.users.filter(val => val.id !== this.state.user.id);
        this.setState({
            users,
            deleteUserDialog: false,
            user: this.emptyUser
        });
        this.usersService.deleteUser(this.state.user.id ).then(this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Cliente apagado', life: 3000 }));

    }
    componentDidMount() {
        this.usersService.getClients().then(data => this.setState({users: data}));
    }

    hideDialog() {
        this.setState({
            submitted: false,
            userDialog: false
        });
    }

    hideDeleteUserDialog() {
        this.setState({ deleteUserDialog: false });
    }


    openNew () {
        this.setState({
            user: this.emptyUser,
            submitted: false,
            userDialog: true
        });
    }

    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button  type="button" label="Criar Novo" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />

            </React.Fragment>
        )
    }
    findIndexById(id) {
        let index = -1;
        for (let i = 0; i < this.state.users.length; i++) {
            if (this.state.users[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }
    saveUser() {
        let state = { submitted: true };
        if (!/^[_A-Za-z0-9-+]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})$/i.test(this.state.user.email)) {
            this.toast.show({ severity: 'error', summary: 'Erro', detail: 'E-mail Inválido', life: 3000 });
            return;
        }
        if (this.state.user.name.trim()) {
            let users = [...this.state.users];
            let user = {...this.state.user};
            if (this.state.user.id) {
                const index = this.findIndexById(this.state.user.id);

                users[index] = user;
                this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Cliente atualizado', life: 3000 });
            }
            else {
                users.push(user);
                this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Cliente Criado', life: 3000 });
            }

            state = {
                ...state,
                users,
                userDialog: false,
                user: this.emptyUser
            };
            this.usersService.createUpdateUser(user);
        }

        this.setState(state);
    }
    onInputNumberChange(e, name) {
        const val = e.value || 0;
        let user = {...this.state.user};
        user[`${name}`] = val;

        this.setState({ user });
    }
    rightToolbarTemplate() {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-external-link"
                        label="Exportar" onClick={this.exportCSV}></Button>
            </React.Fragment>
        )
    }
    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => this.editUser(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => this.confirmDeleteUser(rowData)} />
            </React.Fragment>
        );
    }
    render() {
        const userDialogFooter = (
            <React.Fragment>
                <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={this.saveUser} />
            </React.Fragment>
        );
        const deleteUserDialogFooter = (
            <React.Fragment>
                <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteUserDialog} />
                <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={this.deleteUser} />
            </React.Fragment>
        );

        return <div className="p-grid dashboard">
            <Toast ref={(el) => this.toast = el} />
            <div className="p-col-12">
                <div className="datatable-responsive">
                    <div className="card">
                        <Toolbar className="p-mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                        <DataTable className="p-datatable-responsive" ref={(el) => {
                            this.dt = el;
                        }}  value={this.state.users}
                                   dataKey="id" paginator
                                   rows={10}   paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                   currentPageReportTemplate="{first} até {last} de {totalRecords}"
                                   rowsPerPageOptions={[10, 20, 50]}
                        >
                            <Column body={this.responsiveCustomerId} field="customerId" header="Cliente" sortable></Column>
                            <Column body={this.responsiveName} field="name" header="Nome" sortable></Column>
                            <Column body={this.responsiveEmail} field="email" header="Email" sortable></Column>
                            <Column body={this.responsiveRole} field="role" header="Acesso" sortable></Column>
                            <Column body={this.responsiveActive} field="inactive" header="Ativo" sortable></Column>
                            <Column body={this.responsiveLastLogin} field="lastLogin" header="Acesso" sortable></Column>
                            <Column className="col-options" body={this.actionBodyTemplate}></Column>
                        </DataTable>
                    </div>
                    <Dialog visible={this.state.userDialog} style={{ width: '450px' }} header="Detalhes de Cliente" modal className="p-fluid" footer={userDialogFooter} onHide={this.hideDialog}>
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                                <label htmlFor="customerId">Cliente</label>
                                <InputNumber id="customerId" autoFocus value={this.state.user.customerId} onValueChange={(e) => this.onInputNumberChange(e, 'customerId')} integeronly />
                            </div>

                        </div>
                        <div className="p-field">
                            <label htmlFor="name">Nome</label>
                            <InputText id="name" value={this.state.user.name} onChange={(e) => this.onInputChange(e, 'name')} required  className={classNames({ 'p-invalid': this.state.submitted && !this.state.user.name })} />
                            {this.state.submitted && !this.state.user.name && <small className="p-error">Nome obrigatório.</small>}
                        </div>

                        <div className="p-field">
                            <label htmlFor="email">E-mail</label>
                            <InputText id="email" value={this.state.user.email} onChange={(e) => this.onInputChange(e, 'email')} required  className={classNames({ 'p-invalid': this.state.submitted && !this.state.user.email })} />
                            {this.state.submitted && !this.state.user.email && <small className="p-error">E-mail obrigatório.</small>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="password">Palavra Passe</label>
                            <Password feedback={false} id="password" value={this.state.user.password} onChange={(e) => this.onInputChange(e, 'password')} required  className={classNames({ 'p-invalid': this.state.submitted && !this.state.user.password })} />
                            {this.state.submitted && !this.state.user.password && <small className="p-error">Palavra Passe obrigatória.</small>}
                        </div>
                    </Dialog>
                    <Dialog visible={this.state.deleteUserDialog} style={{ width: '450px' }} header="Confirmação" modal footer={deleteUserDialogFooter} onHide={this.hideDeleteUserDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                            {this.state.user && <span>Tem a certeza que seja apagar o cliente  <b>{this.state.user.name}</b>?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    }
}
