import axios from 'axios';
import {ENDPOINT} from "../Properties";

export class CustomersService {


    getList() {
        return axios.get(ENDPOINT+'/customers')
            .then(res => res.data);
    }

}