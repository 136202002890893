import React from 'react';
import ReactDOM from 'react-dom';
import AppWrapper from './AppWrapper';
import {BrowserRouter} from 'react-router-dom';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'prismjs/themes/prism-coy.css';
import {ENDPOINT} from "./Properties";

ReactDOM.render(
    <BrowserRouter>
        <AppWrapper></AppWrapper>
    </BrowserRouter>,
    document.getElementById('root')
);

export const ssEvents = new EventSource(ENDPOINT + "/dispensers/real-time", {withCredentials: true});
