import React, {Component} from 'react';
import {DispensersService} from "../service/DispensersService";
import {DataView} from 'primereact/dataview';
import {Dropdown} from 'primereact/dropdown';
import {TabPanel, TabView} from "primereact/tabview";
import {Slider} from 'primereact/slider';
import {InputText} from 'primereact/inputtext';
import {Button} from "primereact/button";
import {Inplace, InplaceContent, InplaceDisplay} from 'primereact/inplace';
import {CustomersService} from "../service/CustomersService";
import {ProductsService} from "../service/ProductsService";
import { Divider } from 'primereact/divider';
import {Knob} from "primereact/knob";
import Moment from "moment";
import {ssEvents} from "../index";
import {Dialog} from "primereact/dialog";
import {InputNumber} from "primereact/inputnumber";

export class Devices extends Component {
    constructor(props) {
        super(props);
        this.state = {
            device:{},
            deleteDeviceDialog:false,
            devices: null,
            layout: 'grid',
        };
        this.productsService = new ProductsService();
        this.customersService = new CustomersService();
        this.dispenserService = new DispensersService();
        this.itemTemplate = this.itemTemplate.bind(this);
        this.hideDeleteDeviceDialog = this.hideDeleteDeviceDialog.bind(this);
        this.refreshSse = this.refreshSse.bind(this);
        this.deleteDevice = this.deleteDevice.bind(this);
    }
    componentWillUnmount() {
        ssEvents.removeEventListener("device_health",this.refreshSse);
    }

    refreshSse(e) {
        this.dispenserService.getList().then(data => this.setState({devices: data}));
    }

    componentDidMount() {
        setInterval(() => {
            this.setState({update: true});
        }, 3000);
        this.customersService.getList().then(data => this.setState({customers: data}));
        this.dispenserService.getList().then(data => this.setState({devices: data}));
        this.productsService.getList().then(data => this.setState({products: data}));
        ssEvents.addEventListener("device_health", this.refreshSse);

    }

    onUpdateItemAndSave = (k, d, value) => {
        this.setState(state => {
            const list = state.devices.map((item) => {
                if (d.id === item.id)
                    item[k] = value;
                return item;
            });
            return {
                list,
            };
        }, () => this.dispenserService.updateProps(d));


    }
    onUpdateItem = (k, d, value) => {
        this.setState(state => {
            const list = state.devices.map((item) => {
                if (d.id === item.id)
                    item[k] = value;
                return item;
            });
            return {
                list,
            };
        });


    }

    getDuration(date) {
        var now = Moment(new Date()); //todays date
        var end = Moment(date); // another date
        var duration = Moment.duration(now.diff(end));
        var durationInSeconds = duration.asSeconds();
        let days = durationInSeconds / 86400;
        let hours = (durationInSeconds % 86400) / 3600;
        let mins = (durationInSeconds % 3600) / 60;
        let secs = (mins * 60) % 60;
        days = Math.trunc(days)
        hours = Math.trunc(hours);
        mins = Math.trunc(mins);
        secs = Math.trunc(secs);
        days = days > 0 ? days + " dia" + (days > 1 ? "s" : "") : "";
        hours = hours > 0 ? hours + " hora" + (hours > 1 ? "s" : "") : "";
        mins = mins > 0 ? mins + " minuto" + (mins > 1 ? "s" : "") : "";
        secs = secs > 0 ? secs + " segundo" + (secs > 1 ? "s" : "") : "";
        return (days + " " + hours + " " + mins + " " + secs).trim();
    }
    confirmDeleteDevice(device) {
        this.setState({
            device,
            deleteDeviceDialog: true
        });
    }

    renderGridItem(data) {
        return (
            <div className="p-col-12 p-md-4">
                <div className="product-grid-item card">
                    <div className="p-grid">
                        <div className="p-col-12 p-md-12 p-lg-11">
                            <div className="product-name">
                                <Inplace closable
                                         onClose={() => this.onUpdateItemAndSave("alias", data, data.alias)}>
                                    <InplaceDisplay>
                                        {data.alias || data.name}
                                    </InplaceDisplay>
                                    <InplaceContent>
                                        <InputText value={data.alias}
                                                   onChange={(e) => this.onUpdateItem("alias", data, e.target.value)}
                                                   autoFocus/>
                                    </InplaceContent>
                                </Inplace>
                            </div>
                        </div>
                        <div className="p-col-12 p-md-12 p-lg-1">
                            <Button icon="pi pi-trash" className="p-button-rounded p-button-warning"
                                    onClick={() => this.confirmDeleteDevice(data)}/>
                        </div>

                    </div>

                    <div className="product-grid-item-bottom">
                        <div className="product-grid-item-bottom">
                            <TabView scrollable>
                                <TabPanel header="Geral">
                                    <div className="p-grid">
                                        {data.detergentOneName && <div className="p-col-12 p-md-12 p-lg-4">
                                            <h3>{data.detergentOneName}</h3>
                                            <Knob value={data.detergentOneLevel} valueTemplate={"{value}%"} valueColor={"#39a3f4"}
                                                  readOnly={true}/>

                                        </div>}
                                        {data.detergentTwoName && <div className="p-col-12 p-md-12 p-lg-4">

                                            <h3>{data.detergentTwoName}</h3>
                                            <Knob value={data.detergentTwoLevel} valueTemplate={"{value}%"} valueColor={"#39a3f4"}
                                                  readOnly={true}/>

                                        </div>}
                                        {data.detergentThreeName && <div className="p-col-12 p-md-12 p-lg-4">

                                            <h3>{data.detergentThreeName}</h3>

                                            <Knob value={data.detergentThreeLevel} valueTemplate={"{value}%"} valueColor={"#39a3f4"}
                                                  readOnly={true}/>
                                        </div>}
                                        {data.detergentFourName && <div className="p-col-12 p-md-12 p-lg-4">
                                            <h3>{data.detergentFourName}</h3>
                                            <Knob value={data.detergentFourLevel} valueTemplate={"{value}%"} valueColor={"#39a3f4"}
                                                  readOnly={true}/>
                                        </div>}
                                        {data.detergentFiveName && <div className="p-col-12 p-md-12 p-lg-4">
                                            <h3>{data.detergentFiveName}</h3>
                                            <Knob value={data.detergentFiveLevel} valueTemplate={"{value}%"} valueColor={"#39a3f4"}
                                                  readOnly={true}/>
                                        </div>}
                                        {data.detergentSixName && <div className="p-col-12 p-md-12 p-lg-4">

                                            <h3>{data.detergentSixName}</h3>
                                            <Knob value={data.detergentSixLevel} valueTemplate={"{value}%"} valueColor={"#39a3f4"}
                                                  readOnly={true}/>

                                        </div>}
                                    </div>
                                    <div className="product-grid-item-top border-top">
                                        <div className="p-grid">
                                            <div className="p-col-12 p-md-12 p-lg-2">
                                                <span className="product-category">Cliente</span>
                                            </div>
                                            <div className="p-col-12 p-md-12 p-lg-10">
                                                <Dropdown optionLabel="name" optionValue={"number"}
                                                          value={data.clientNumber} showClear
                                                          options={this.state.customers}
                                                          onChange={(e) => this.onUpdateItemAndSave("clientNumber", data, e.target.value)}
                                                          placeholder="Escolha o Cliente"/>
                                            </div>
                                        </div>


                                        <div className="p-grid">
                                            <div className="p-col-12 p-md-12 p-lg-2">
                                                <span className="product-category">Serial</span>
                                            </div>
                                            <div className="p-col-12 p-md-12 p-lg-4">
                            <span
                                className="product-category status-ok">{data.name}</span>
                                            </div>
                                            <div className="p-col-12 p-md-12 p-lg-2">
                                                <span className="product-category">Versão</span>
                                            </div>
                                            <div className="p-col-12 p-md-12 p-lg-4">
                            <span
                                className="product-category status-ok">2.{data.version}   </span>
                                            </div>
                                        </div>
                                        <div className="p-grid">
                                            <div className="p-col-12 p-md-12 p-lg-2">
                                                <span className="product-category">Estado</span>
                                            </div>
                                            <div className="p-col-12 p-md-12 p-lg-10">
                            <span
                                className={`product-category status-${data.online ? "ok" : "error"}`}>{data.online ? "Ligado" : 'Desligado'} {data.online ? "desde " + Moment(data.onlineOn).format('DD-MM-YYYY HH:mm:ss') : "desde " + Moment(data.offlineOn).format('DD-MM-YYYY HH:mm:ss')} {"(" + this.getDuration(data.online ? data.onlineOn : data.offlineOn) + ")"}  </span>
                                            </div>
                                        </div>

                                    </div>
                                </TabPanel>
                                <TabPanel header="Detergentes">
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-6 p-lg-4">
                                        <span className="p-float-label">
                                            <h3>D1</h3>
                                            <Dropdown id="product-filter" optionLabel="name" optionValue={"name"}
                                                      value={data.detergentOneName}
                                                      options={this.state.products} showClear
                                                      onChange={(e) => this.onUpdateItem("detergentOneName", data, e.value)}/>
                                        </span>
                                            <InputNumber inputClassName="input-calibration" value={data.detergentOneStaticCalibration}
                                                         onChange={(e) => this.onUpdateItemAndSave("detergentOneStaticCalibration", data, e.value)}
                                                         autoFocus/> ml/30s
                                        </div>
                                        <div className="p-col-12 p-md-6 p-lg-4">
                                            <span className="p-float-label">
                                            <h3>D2</h3>
                                           <Dropdown id="product-filter" optionLabel="name" optionValue={"name"}
                                                     value={data.detergentTwoName}
                                                     options={this.state.products} showClear
                                                     onChange={(e) => this.onUpdateItem("detergentTwoName", data, e.value)}/>
                                        </span>
                                            <InputNumber inputClassName="input-calibration" value={data.detergentTwoStaticCalibration}
                                                         onChange={(e) => this.onUpdateItemAndSave("detergentTwoStaticCalibration", data, e.value)}
                                                         autoFocus/> ml/30s
                                        </div>
                                        <div className="p-col-12 p-md-6 p-lg-4">
                                            <span className="p-float-label">
                                            <h3>D3</h3>
                                         <Dropdown id="product-filter" optionLabel="name" optionValue={"name"}
                                                   value={data.detergentThreeName}
                                                   options={this.state.products} showClear
                                                   onChange={(e) => this.onUpdateItem("detergentThreeName", data, e.value)}/>
                                        </span>
                                            <InputNumber inputClassName="input-calibration" value={data.detergentThreeStaticCalibration}
                                                         onChange={(e) => this.onUpdateItemAndSave("detergentThreeStaticCalibration", data, e.value)}
                                                         autoFocus/> ml/30s
                                        </div>
                                        <div className="p-col-12 p-md-6 p-lg-4">
                                            <span className="p-float-label">
                                            <h3>D4</h3>
                                            <Dropdown id="product-filter" optionLabel="name" optionValue={"name"}
                                                      value={data.detergentFourName}
                                                      options={this.state.products} showClear
                                                      onChange={(e) => this.onUpdateItem("detergentFourName", data, e.value)}/>
                                        </span>
                                            <InputNumber inputClassName="input-calibration" value={data.detergentFourStaticCalibration}
                                                         onChange={(e) => this.onUpdateItemAndSave("detergentFourStaticCalibration", data, e.value)}
                                                         autoFocus/> ml/30s
                                        </div>
                                        <div className="p-col-12 p-md-6 p-lg-4">
                                            <span className="p-float-label">
                                            <h3>D5</h3>
                                           <Dropdown id="product-filter" optionLabel="name" optionValue={"name"}
                                                     value={data.detergentFiveName}
                                                     options={this.state.products} showClear
                                                     onChange={(e) => this.onUpdateItem("detergentFiveName", data, e.value)}/>
                                        </span>
                                            <InputNumber inputClassName="input-calibration" value={data.detergentFiveStaticCalibration}
                                                         onChange={(e) => this.onUpdateItemAndSave("detergentFiveStaticCalibration", data, e.value)}
                                                         autoFocus/> ml/30s
                                        </div>
                                        <div className="p-col-12 p-md-6 p-lg-4">
                                            <span className="p-float-label">
                                            <h3>D6</h3>
                                           <Dropdown id="product-filter" optionLabel="name" optionValue={"name"}
                                                     value={data.detergentSixName}
                                                     options={this.state.products} showClear
                                                     onChange={(e) => this.onUpdateItem("detergentSixName", data, e.value)}/>
                                        </span>
                                            <InputNumber  inputClassName="input-calibration" value={data.detergentSixStaticCalibration}
                                                          onChange={(e) => this.onUpdateItemAndSave("detergentSixStaticCalibration", data, e.value)}
                                                          autoFocus/> ml/30s
                                        </div>
                                    </div>
                                    <div className="p-col-12">
                                        <Button disabled={!data.online}
                                                onClick={() => this.dispenserService.updates(data).then(d => this.onUpdateItem("version", d, d.version))}
                                                name={data.name} icon="pi pi-save" label="Guardar e Enviar Configuração"
                                                className="p-col-12 p-button-rounded"/>
                                    </div>
                                </TabPanel>
                                <TabPanel header="Pulverizador">
                                    <div className="p-grid p-nogutte">
                                        {data.detergentOneName && <div className="p-col-6 p-md-6 p-lg-3">
                                            <h4>{data.detergentOneName}</h4>
                                            <Slider value={data.detergentOneDispenser}
                                                    onChange={(e) => this.onUpdateItem("detergentOneDispenser", data, e.value)}
                                                    orientation="vertical"/>
                                            <p>{data.detergentOneDispenser}s</p>
                                        </div>}
                                        {data.detergentTwoName && <div className="p-col-6 p-md-6 p-lg-3">
                                            <h4>{data.detergentTwoName}</h4>
                                            <Slider value={data.detergentTwoDispenser}
                                                    onChange={(e) => this.onUpdateItem("detergentTwoDispenser", data, e.value)}
                                                    orientation="vertical"/>
                                            <p>{data.detergentTwoDispenser}s</p>
                                        </div>}
                                        {data.detergentThreeName && <div className="p-col-6 p-md-6 p-lg-3">
                                            <h4>{data.detergentThreeName}</h4>
                                            <Slider value={data.detergentThreeDispenser}
                                                    onChange={(e) => this.onUpdateItem("detergentThreeDispenser", data, e.value)}
                                                    orientation="vertical"/>
                                            <p>{data.detergentThreeDispenser}s</p>
                                        </div>}
                                        {data.detergentFourName && <div className="p-col-6 p-md-6 p-lg-3">
                                            <h4>{data.detergentFourName}</h4>
                                            <Slider value={data.detergentFourDispenser}
                                                    onChange={(e) => this.onUpdateItem("detergentFourDispenser", data, e.value)}
                                                    orientation="vertical"/>
                                            <p>{data.detergentFourDispenser}s</p>
                                        </div>}
                                        {data.detergentFiveName && <div className="p-col-6 p-md-6 p-lg-3">
                                            <h4>{data.detergentFiveName}</h4>
                                            <Slider value={data.detergentFiveDispenser}
                                                    onChange={(e) => this.onUpdateItem("detergentFiveDispenser", data, e.value)}
                                                    orientation="vertical"/>
                                            <p>{data.detergentFiveDispenser}s</p>
                                        </div>}
                                        {data.detergentSixName && <div className="p-col-6 p-md-6 p-lg-3">
                                            <h4>{data.detergentSixName}</h4>
                                            <Slider value={data.detergentSixDispenser}
                                                    onChange={(e) => this.onUpdateItem("detergentSixDispenser", data, e.value)}
                                                    orientation="vertical"/>
                                            <p>{data.detergentSixDispenser}s</p>
                                        </div>}
                                        <div className="p-col-12">
                                            <h4>Água</h4>
                                            <Slider max="500" value={data.waterDispenser}
                                                    onChange={(e) => this.onUpdateItem("waterDispenser", data, e.value)}/>
                                            <p>{data.waterDispenser} segundos</p>
                                        </div>
                                    </div>
                                    <div className="p-col-12">
                                        <Button disabled={!data.online}
                                                onClick={() => this.dispenserService.updates(data).then(d => this.onUpdateItem("version", d, d.version))}
                                                name={data.name} icon="pi pi-save" label="Guardar e Enviar Configuração"
                                                className="p-col-12 p-button-rounded"/>
                                    </div>
                                </TabPanel>
                                <TabPanel header="Balde">
                                    <div className="p-grid p-nogutte">
                                        {data.detergentOneName && <div className="p-col-6 p-md-6 p-lg-3">
                                            <h4>{data.detergentOneName}</h4>
                                            <Slider value={data.detergentOneBucket}
                                                    onChange={(e) => this.onUpdateItem("detergentOneBucket", data, e.value)}
                                                    orientation="vertical"/>
                                            <p>{data.detergentOneBucket}s</p>
                                        </div>}
                                        {data.detergentTwoName && <div className="p-col-6 p-md-6 p-lg-3">
                                            <h4>{data.detergentTwoName}</h4>
                                            <Slider value={data.detergentTwoBucket}
                                                    onChange={(e) => this.onUpdateItem("detergentTwoBucket", data, e.value)}
                                                    orientation="vertical"/>
                                            <p>{data.detergentTwoBucket}s</p>
                                        </div>}
                                        {data.detergentThreeName && <div className="p-col-6 p-md-6 p-lg-3">
                                            <h4>{data.detergentThreeName}</h4>
                                            <Slider value={data.detergentThreeBucket}
                                                    onChange={(e) => this.onUpdateItem("detergentThreeBucket", data, e.value)}
                                                    orientation="vertical"/>
                                            <p>{data.detergentThreeBucket}s</p>
                                        </div>}
                                        {data.detergentFourName && <div className="p-col-6 p-md-6 p-lg-3">
                                            <h4>{data.detergentFourName}</h4>
                                            <Slider value={data.detergentFourBucket}
                                                    onChange={(e) => this.onUpdateItem("detergentFourBucket", data, e.value)}
                                                    orientation="vertical"/>
                                            <p>{data.detergentFourBucket}s</p>
                                        </div>}
                                        {data.detergentFiveName && <div className="p-col-6 p-md-6 p-lg-3">
                                            <h4>{data.detergentFiveName}</h4>
                                            <Slider value={data.detergentFiveBucket}
                                                    onChange={(e) => this.onUpdateItem("detergentFiveBucket", data, e.value)}
                                                    orientation="vertical"/>
                                            <p>{data.detergentFiveBucket}s</p>
                                        </div>}
                                        {data.detergentSixName && <div className="p-col-6 p-md-6 p-lg-3">
                                            <h4>{data.detergentSixName}</h4>
                                            <Slider value={data.detergentSixBucket}
                                                    onChange={(e) => this.onUpdateItem("detergentSixBucket", data, e.value)}
                                                    orientation="vertical"/>
                                            <p>{data.detergentSixBucket}s</p>
                                        </div>}
                                        <div className="p-col-12">
                                            <h4>Água</h4>
                                            <Slider max="500" value={data.waterBucket} orientation="horizontal"
                                                    onChange={(e) => this.onUpdateItem("waterBucket", data, e.value)}/>
                                            <p>{data.waterBucket} segundos</p>
                                        </div>
                                    </div>
                                    <div className="p-col-12">
                                        <Button disabled={!data.online}
                                                onClick={() => this.dispenserService.updates(data).then(d => this.onUpdateItem("version", d, d.version))}
                                                name={data.name} icon="pi pi-save" label="Guardar e Enviar Configuração"
                                                className="p-col-12 p-button-rounded"/>
                                    </div>
                                </TabPanel>
                                <TabPanel header="Máquina">
                                    <div className="p-grid p-nogutte">
                                        {data.detergentOneName && <div className="p-col-6 p-md-6 p-lg-3">
                                            <h4>{data.detergentOneName}</h4>
                                            <Slider value={data.detergentOneMachine}
                                                    onChange={(e) => this.onUpdateItem("detergentOneMachine", data, e.value)}
                                                    orientation="vertical"/>
                                            <p>{data.detergentOneMachine}s</p>
                                        </div>}
                                        {data.detergentTwoName && <div className="p-col-6 p-md-6 p-lg-3">
                                            <h4>{data.detergentTwoName}</h4>
                                            <Slider value={data.detergentTwoMachine}
                                                    onChange={(e) => this.onUpdateItem("detergentTwoMachine", data, e.value)}
                                                    orientation="vertical"/>
                                            <p>{data.detergentTwoMachine}s</p>
                                        </div>}
                                        {data.detergentThreeName && <div className="p-col-6 p-md-6 p-lg-3">
                                            <h4>{data.detergentThreeName}</h4>
                                            <Slider value={data.detergentThreeMachine}
                                                    onChange={(e) => this.onUpdateItem("detergentThreeMachine", data, e.value)}
                                                    orientation="vertical"/>
                                            <p>{data.detergentThreeMachine}s</p>
                                        </div>}
                                        {data.detergentFourName && <div className="p-col-6 p-md-6 p-lg-3">
                                            <h4>{data.detergentFourName}</h4>
                                            <Slider value={data.detergentFourMachine}
                                                    onChange={(e) => this.onUpdateItem("detergentFourMachine", data, e.value)}
                                                    orientation="vertical"/>
                                            <p>{data.detergentFourMachine}s</p>
                                        </div>}
                                        {data.detergentFiveName && <div className="p-col-6 p-md-6 p-lg-3">
                                            <h4>{data.detergentFiveName}</h4>
                                            <Slider value={data.detergentFiveMachine}
                                                    onChange={(e) => this.onUpdateItem("detergentFiveMachine", data, e.value)}
                                                    orientation="vertical"/>
                                            <p>{data.detergentFiveMachine}s</p>
                                        </div>}
                                        {data.detergentSixName && <div className="p-col-6 p-md-6 p-lg-3">
                                            <h4>{data.detergentSixName}</h4>
                                            <Slider value={data.detergentSixMachine}
                                                    onChange={(e) => this.onUpdateItem("detergentSixMachine", data, e.value)}
                                                    orientation="vertical"/>
                                            <p>{data.detergentSixMachine}s</p>
                                        </div>}
                                        <div className="p-col-12">
                                            <h4>Água</h4>
                                            <Slider max="500" value={data.waterMachine}
                                                    onChange={(e) => this.onUpdateItem("waterMachine", data, e.value)}/>
                                            <p>{data.waterMachine} segundos</p>
                                        </div>
                                    </div>
                                    <div className="p-col-12">
                                        <Button disabled={!data.online}
                                                onClick={() => this.dispenserService.updates(data).then(d => this.onUpdateItem("version", d, d.version))}
                                                name={data.name} icon="pi pi-save" label="Guardar e Enviar Configuração"
                                                className="p-col-12 p-button-rounded"/>
                                    </div>
                                </TabPanel>

                            </TabView>
                        </div>

                    </div>

                </div>
            </div>
        );
    }

    itemTemplate(product) {
        if (!product) {
            return;
        }
        return this.renderGridItem(product);
    }

    deleteDevice() {
        let devices = this.state.devices.filter(val => val.id !== this.state.device.id);
        this.dispenserService.delete(this.state.device.id).then(data =>  this.setState({
            devices,
            deleteDeviceDialog: false,
            device: {}
        }));

    }
    hideDeleteDeviceDialog() {
        this.setState({deleteDeviceDialog: false});
    }
    render() {
        const deleteDeviceDialogFooter = (
            <React.Fragment>
                <Button label="Não" icon="pi pi-times" className="p-button-text"
                        onClick={this.hideDeleteDeviceDialog}/>
                <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={this.deleteDevice}/>
            </React.Fragment>
        );
        return (


            <div className="card">
                <div className="dataview-detergent">

                    <DataView value={this.state.devices} layout={this.state.layout}
                              itemTemplate={this.itemTemplate}/>

                </div>
                <Dialog visible={this.state.deleteDeviceDialog} style={{width: '450px'}} header="Confirmação" modal
                        footer={deleteDeviceDialogFooter} onHide={this.hideDeleteDeviceDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{fontSize: '2rem'}}/>
                        {this.state.device && <span>Tem a certeza que deseja apagar o dispensador com o número de serie <b>{this.state.device.name}</b>?</span>}
                    </div>
                </Dialog>

            </div>

        );
    }

}
