import {LOGIN_FAILURE, LOGIN_REQUEST, LOGIN_SUCCESS, LOGOUT_REQUEST, SESSION_RESET} from "../actions/auth";
import {REHYDRATE} from 'redux-persist/lib/constants';

const INITIAL_STATE = {
    isFetching: false,
    user: null,
    token: null,
    isAuthenticated: false
};

export default function (state = INITIAL_STATE, {type, payload}) {
    switch (type) {
        case REHYDRATE:
            if (payload && payload.auth) {
                return {
                    ...payload.auth,
                };
            }
            return state;
        case LOGIN_REQUEST :
            return {
                ...state,
                isFetching: true,
                isAuthenticated: false
            };
        case SESSION_RESET :
        case LOGOUT_REQUEST :
        case LOGIN_FAILURE :
            return {
                ...state,
                user: null,
                token: null,
                isFetching: false,
                isAuthenticated: false
            };

        case LOGIN_SUCCESS :
            return {
                ...state,
                user: payload.user,
                token: payload.token,
                isFetching: false,
                isAuthenticated: true
            };
        default:
            return state;

    }
}