import axios from 'axios';
import {resetSessionActionCreator} from "../actions/auth";

export const setupRequestInterceptor = (store) => {
    axios.interceptors.request.use(
        reqConfig => {

            const {auth} = store.getState();
            const {token} = auth;
            const newConfig = reqConfig;
            if (token) {
                newConfig.headers.Authorization = 'Bearer ' + token;
            }

            return newConfig;
        },
        err => Promise.reject(err),
    );
};
export const setupResponseInterceptor = (store) => {
    axios.interceptors.response.use(response => {
        return response;
    }, error => {
   if (error.response && error.response.status === 401) {
            store.dispatch(resetSessionActionCreator());
        }
        return Promise.reject(error);
    });
};