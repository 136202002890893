import React, {Component} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {InputText} from 'primereact/inputtext';
import {connect} from "react-redux";
import {Dialog} from "primereact/dialog";
import {ProductsService} from "../service/ProductsService";
import {Toolbar} from "primereact/toolbar";


class Products extends Component {


    responsiveName(rowData) {
        return (
            <React.Fragment>
                {rowData.name}
            </React.Fragment>
        );
    }
    editProduct(product) {
        this.setState({
            product,
            productDialog: true
        });
    }
    emptyProduct = {
        name: '',
    };
    constructor() {
        super();
        this.state = {
            editingRows: {},
            products: [],
            deleteProductDialog:false,
            productDialog:false,
            customers: [],
            product: {}
        };
        this.productsService = new ProductsService();
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.editProduct = this.editProduct.bind(this);
        this.openNew = this.openNew.bind(this);
        this.saveProduct = this.saveProduct.bind(this);
        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.rightToolbarTemplate = this.rightToolbarTemplate.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.exportCSV = this.exportCSV.bind(this);
        this.hideDeleteProductDialog = this.hideDeleteProductDialog.bind(this);
        this.deleteProduct = this.deleteProduct.bind(this);
        this.confirmDeleteProduct = this.confirmDeleteProduct.bind(this);
    }


    exportCSV() {
        this.dt.exportCSV();
    }
    confirmDeleteProduct(product) {
        this.setState({
            product,
            deleteProductDialog: true
        });
    }
    componentDidMount() {
        this.productsService.getList().then(data => this.setState({products: data}));
    }
    hideDialog() {
        this.setState({
            submitted: false,
            productDialog: false
        });
    }
    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2"
                        onClick={() => this.editProduct(rowData)}/>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning"
                        onClick={() => this.confirmDeleteProduct(rowData)}/>
            </React.Fragment>
        );
    }
    saveProduct(){
        this.productsService.createUpdate(this.state.product).then(data => this.productsService.getList().then(data => this.setState({
            productDialog: false,
            products: data})));
    }
    hideDeleteProductDialog() {
        this.setState({deleteProductDialog: false});
    }
    deleteProduct() {
        let products = this.state.products.filter(val => val.id !== this.state.product.id);
        this.productsService.delete(this.state.product.id).then(data =>  this.setState({
            products,
            deleteProductDialog: false,
            product: {}
        }));

    }
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button  type="button" label="Criar Novo" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />

            </React.Fragment>
        )
    }
    rightToolbarTemplate() {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-external-link"
                        label="Exportar" onClick={this.exportCSV}></Button>
            </React.Fragment>
        )
    }

    openNew () {
        this.setState({
            product: this.emptyProduct,
            submitted: false,
            productDialog: true
        });
    }
    render() {
        
        const deleteProductDialogFooter = (
            <React.Fragment>
                <Button label="Não" icon="pi pi-times" className="p-button-text"
                        onClick={this.hideDeleteProductDialog}/>
                <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={this.deleteProduct}/>
            </React.Fragment>
        );
        const productDialogFooter = (
            <React.Fragment>
                <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog}/>
                <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={this.saveProduct}/>
            </React.Fragment>
        );

        return <div className="p-grid dashboard">
            <div className="p-col-12">
                <div className="datatable-responsive">
                    <div className="card">
                        <Toolbar className="p-mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                        <DataTable  filterDisplay="row" editMode="row" dataKey="uuid" onRowEditInit={this.onRowEditInit}
                                   onRowEditSave={this.onRowEditSave} onRowEditCancel={this.onRowEditCancel}
                                   className="p-datatable-responsive" ref={(el) => {
                            this.dt = el;
                        }}  value={this.state.products}>

                            <Column filterMatchMode="contains" filter body={this.responsiveName} field="clientName" header="Nome"
                                    sortable></Column>
                            {this.props.user.platformAdmin &&
                                <Column className="col-options" body={this.actionBodyTemplate}></Column>}
                        </DataTable>
                    </div>
                    <Dialog visible={this.state.deleteProductDialog} style={{width: '450px'}} header="Confirmação" modal
                            footer={deleteProductDialogFooter} onHide={this.hideDeleteProductDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{fontSize: '2rem'}}/>
                            {this.state.product && <span>Tem a certeza que deseja apagar o produto<b> {this.state.product.name}</b>?</span>}
                        </div>
                    </Dialog>
                    <Dialog visible={this.state.productDialog} style={{width: '450px'}} header="Detalhes do Produto" modal
                            className="p-fluid" footer={productDialogFooter} onHide={this.hideDialog}>
                        <div className="p-field">
                            <label htmlFor="name">Produto</label>
                        </div>
                        <div className="p-field">
                            <label htmlFor="name">Nome</label>
                            <InputText  id="name" value={this.state.product.name}
                                       onChange={(e) => this.setState({
                                           product: {
                                               ...this.state.product,
                                               name: e.target.value
                                           }
                                       })} required/>
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    }
}

function mapDispatchToProps({auth}) {
    const {user} = auth;
    return {user};
}

export default connect(mapDispatchToProps, {})(Products)