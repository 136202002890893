import axios from 'axios';
import {ENDPOINT} from "../Properties";

export class DispensersService {
    getList() {
        return axios.get(ENDPOINT+'/dispensers')
            .then(res => res.data);
    }
    getCount() {
        return axios.get(ENDPOINT+'/dispensers/count/')
                .then(res => res.data);
    }
    getOnline() {
        return axios.get(ENDPOINT+'/dispensers/count/online/')
            .then(res => res.data);
    }
    delete(id) {
        return axios.delete(ENDPOINT+'/dispensers/'+id)
            .then(res => res.data);
    }

    updates(data) {
        return axios.post(ENDPOINT+'/dispensers/',data)
            .then(res => res.data);
    }
    updateProps(data) {
        return axios.put(ENDPOINT+'/dispensers/'+data.id,data)
            .then(res => res.data);
    }

}