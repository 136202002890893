import axios from 'axios';
import {ENDPOINT} from "../Properties";

export class CardsService {
    

    getList() {
        return axios.get(ENDPOINT+'/cards')
            .then(res => res.data);
    }

    update(card) {
        return axios.put(ENDPOINT+'/cards/'+card.id,card)
            .then(res => res.data);
    }
    delete(id) {
        return axios.delete(ENDPOINT+'/cards/'+id)
            .then(res => res.data);
    }
}