import axios from 'axios';
import {ENDPOINT} from "../Properties";

export class ProductsService {
    

    getList() {
        return axios.get(ENDPOINT+'/products')
            .then(res => res.data);
    }

    createUpdate(product){
        return axios.post(ENDPOINT+'/products',product)
            .then(res => res.data);
    }

    delete(id){
        return axios.delete(ENDPOINT+'/products/'+id)
            .then(res => res.data);
    }

}