import axios from 'axios';
import {ENDPOINT} from "../Properties";

export class UsersService {
    

    getList() {
        return axios.get(ENDPOINT+'/users')
            .then(res => res.data);
    }

    getClients() {
        return axios.get(ENDPOINT+'/users/clients')
            .then(res => res.data);
    }

    createUpdateUser(user){
        return axios.post(ENDPOINT+'/users/create-client',user)
            .then(res => res.data);
    }

    deleteUser(id){
        return axios.delete(ENDPOINT+'/users/delete-client/'+id)
            .then(res => res.data);
    }

}